import axios from "axios";
import { useEffect, useState, createContext } from "react";

export const BlogSociauxContext = createContext();

const BlogSociauxContextProvider = (props) => {
  const [blogSociaux, setblogSociaux] = useState([]);
  const [reloadBlogSociaux, setReloadBlogSociaux] = useState(true);


  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACK}/blogs/getBlogs`)
      .then((res) => {; setblogSociaux(res.data['blogs'])})
      .catch(err => console.log(err));
  }, [reloadBlogSociaux]);

  return (
    <BlogSociauxContext.Provider
      value={{
        blogSociaux,
        setblogSociaux,
        reloadBlogSociaux,
        setReloadBlogSociaux
      }}
    >
      {props.children}
    </BlogSociauxContext.Provider>
  );
};

export default BlogSociauxContextProvider;
import '../Styles/View4.css';
import StackView4 from "../SubViews/View4/StackView4"
import React, { useState, useEffect } from 'react';
import im1 from "../Assets/view4/im1.png";
import Question from '../SubViews/View4/Question';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import waveTop from "../Assets/view4/waveTop.png";
import waveBottom from "../Assets/view4/waveBottom.png";
import { Parallax } from 'react-scroll-parallax';




function View4() {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setScreenWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () =>   {
        window.removeEventListener('resize', handleResize);
      };
    }, []);



    return(
        <div className='View4Container' id="FAQ">
            
            <Parallax translateX={['0vw', '100vw']} className='waveSperatorContainer' >

        <img src={waveTop} className='waveTopView4' />
          </Parallax>

        <h1 className='HeaderView4'>On nous demande souvent...</h1>
        <div className='View4StackContainer'>
              <Question
              question='Que signifie le nom UGP PME ?'
              reponse={`
              « Unknown Growth Potential » (potentiel de croissance insoupçonné).

              Les PME doivent pouvoir s'affirmer sur leur marché pour réaliser leur plein potentiel. 
              
              Nous sommes là pour qu’elles deviennent ce qu’elles peuvent devenir grâce aux outils de marketing et de stratégie de communication qui leur semblent inaccessibles.              `}
              index='0'
              />
              <Question
              question='Quelle est votre singularité ?'
              reponse={`Nos expertises complémentaires vous permettent de bénéficier d'une offre à 360° et 100% personnalisée.

              Nous sommes une agence spécialisée en stratégie globale.
              
              Concrètement on vous accompagne dans toutes les phases du développement de votre activité : création, pilotage, conseil.
              
              Nous allons au-delà de la seule communication.
              `}
              index='1'
              />
              <Question
              question='Pourquoi vous sollicitez pour notre business ?'
              reponse={`A l'origine d’UGP PME, 2 dirigeants d’entreprises avec une expérience de plus de 15 ans dans le monde de la PME et des professions libérales.

              Ils connaissent sur le bout des doigts les enjeux et les moyens de les développer.
              
              Fiabilité, confiance et réactivité auprès de nos clients, sont des valeurs essentielles à nos yeux.`}
              index='2'
              />
              <Question
              question='Quelles sont les prestations les plus sollicitées ?'
              reponse={`UGP PME intervient principalement dans l’élaboration d’une stratégie de développement global avec notemment :

              - (Re)Définition de marque
              - Réalisation et suivi site web
              - Marketing de vente`}
              index='3'
              />
              <Question
              question='Comment se déroule le diagnostic ?'
              reponse='Après avoir échangé avec le dirigeant d’entreprise, nous intervenons dans l’entreprise sur une période coupée en 3 phases : Entretien avec le dirigeant et les personnes clés de l’entreprise Phase d’observation en immersion dans l’entreprise Synthèse et restitution sur les forces et points d’amélioration de l’entreprise L’objectif du diagnostic sera d’aboutir à des modules de solutions répondant aux améliorations identifiées en vue d’optimiser le potentiel de croissance ou/ et de communication de l’entreprise.'
              index='4'
              />
              <Question
              question='Pourquoi faire appel à votre agence de conseil en communication  et business ?'
              reponse='Nous avons le souci d’une approche globale de votre stratégie marque et de son potentiel de croissance. En nous confiant votre communication, vous avez la garantie d’une cohérence à 100% dans la stratégie de développement et votre communication pour réaliser tout votre potentiel. L’image de votre entreprise ainsi que votre nom de marque peut alors se prévaloir d’une identité forte auprès du public cible sur un marché concurrentiel. Pour en savoir plus, n’hésitez pas à nous contacter.'
              index='5'
              />
              <Question
               question='Vous faites quoi concrètement sur la communication ?'
               reponse={`Vous êtes accompagné sur toutes les problématiques en lien avec le marketing, l’image de marque et le digital.

               De la création d'une stratégie de communication sur mesure à la rédaction de vos contenus.`}
               index='6'
              />
              <Question
                question='Quelle différence entre votre agence UGP PME et un freelance ?'
                reponse={`
                Si vous cherchez la sécurité, la tranquillité d'esprit, un service sur mesure et du long terme alors vous êtes au bon endroit.

Si en revanche vous cherchez du “one shot“, une seule compétence, et que vous êtes prêt à envoyer des dizaines de relances pour avoir un résultat, alors vous devriez faire appel à un freelance.
                `}
                index='7'
              />




        </div>
        
        <Parallax translateX={['0vw', '-100vw']} className='waveBottomView4Containerr'>

        <img src={waveBottom} className='waveBottomView4'  />
        </Parallax>

        </div>)


}

export default View4;
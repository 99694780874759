import '../Styles/View2.css';
import Stack from '../SubViews/View2/Stack';
import picto1 from '../Assets/view2/Pictos1.png';
import picto2 from '../Assets/view2/Pictos2.png';
import picto3 from '../Assets/view2/Pictos3.png';
import picto4 from '../Assets/view2/Pictos4.png';
import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Pagination } from 'swiper';
import { Parallax } from 'react-scroll-parallax';
import { useParallax } from 'react-scroll-parallax';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import waveTop from "../Assets/view4/waveTop.png";


function View2() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () =>   {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (screenWidth < 600){
    return(
      <div className='View2Container' id="View2">
            <Parallax translateX={['0vw', '100vw']} className='waveSperatorContainer' >
        <img src={waveTop} className='waveTopView4' />
      </Parallax>



      <div id="titleView2">Révélez tout votre potentiel grâce à une stratégie de croissance  sur mesure pour VOTRE entreprise</div>
      <Swiper
      loop={true}
      slidesPerView={1}
      navigation={true}
      pagination={true}
      modules={[Pagination, Navigation]} 
    >
      <SwiperSlide>                   <Stack
  imgSrc={picto1}
  title="AUDIT"
  text={`Stratégie de croissance
Nouveaux marchés
Plan stratégique international`}
addedText="Gagner en clarté et découvrir 
de nouvelles opportunités pour prendre de l'avance sur vos concurrents."
index='1'
/>
          </SwiperSlide>
      <SwiperSlide>              <Stack
          index='2'
          imgSrc={picto2}
          title="CONSEIL"
          text={`Transformation et conduite du changement
          
          Méthodologie et pilotage de projet`}
          addedText="Éviter les erreurs grâce à des consultants experts de leurs domaines."

          />
          
          </SwiperSlide>
      <SwiperSlide>
      <Stack
          imgSrc={picto3}
          title="MARKETING ET COM'"
          text={`Charte graphique / storytelling
          
          
          Événementiel
          
          Ligne éditoriale`}
          addedText="La garantie d’une cohérence à 100% et d’une identité forte  pour sortir du lot."
          index='3'
          />

      </SwiperSlide>
      <SwiperSlide>
      <Stack  
          imgSrc={picto4}
          title="SOCIAL MÉDIA"
          text={`Rédaction et publications
          
          Community management
          
          Newsletter`}
          addedText="Pour gagner du temps, des leads et fidéliser vos clients actuels."
          index='4'
          />
      </SwiperSlide>
      
    </Swiper>


        <div className='View2Footer'>
       <div className='View2Msg'>
        En bref, une offre globale avec des solutions 100% personnalisées et innovantes pour capter l’attention de vos cibles, développer votre notoriété, et multiplier vos ventes.
  </div> 

    <a href="#Contact">
    <div class="gradient-color-2">
      Démarquez-vous
    </div>
    </a>


  </div> 

  </div> 

    )
  }

  return (
    <div className='View2Container' id="View2">
      
      <Parallax translateX={['0vw', '100vw']} className='waveSperatorContainer' >
        <img src={waveTop} className='waveTopView4' />
      </Parallax>

      <div id="titleView2">Révélez tout votre potentiel  grâce à une stratégie de croissance  sur mesure pour VOTRE entreprise</div>
      <div id="stacksContainer">
      <Stack
  imgSrc={picto1}
  title="AUDIT"
  text={`Stratégie de croissance

Nouveaux marchés

Plan stratégique international`}
addedText="Gagner en clarté et découvrir
de nouvelles opportunités pour prendre de l'avance sur vos concurrents."

index='1'
/>

             <Stack
          imgSrc={picto2}
          title="CONSEIL"
          text={`Transformation et conduite du changement
          
          Méthodologie et pilotage de projet`}
          addedText="Éviter les erreurs grâce à des consultants experts de leurs domaines."
          index='2'
          />

<Stack
          imgSrc={picto3}
          title="MARKETING ET COM'"
          text={`Charte graphique / storytelling
          
          
          Événementiel
          
          Ligne éditoriale`}
          addedText="La garantie d’une cohérence à 100% et d’une identité forte pour sortir du lot."
          index='3'
          />

<Stack  
          imgSrc={picto4}
          title="SOCIAL MÉDIA"
          text={`Rédaction et publications
          
          Community management
          
          Newsletter`}
          addedText="Pour gagner du temps, des leads, de la Crédibilité et fidéliser vos clients actuels."
          index='4'
          /> 


       </div>

       <div className='View2Footer'>
       <div className='View2Msg'>
        En bref, une offre globale avec des solutions 100% personnalisées et innovantes pour capter l’attention de vos cibles, développer votre notoriété, et multiplier vos ventes.
  </div> 

  <a href="#Contact">
    <div class="gradient-color-2">
      Démarquez-vous
    </div>
</a>


  </div> 

    </div>
  );
}
export default View2;
import './Home.css';
import Landing from './Views/Landing';
import View2 from './Views/View2';
import View3 from './Views/View3';
import View4 from './Views/View4';
import View5 from './Views/View5';
import View6 from './Views/View6';
import Footer from './Views/Footer'
import ViewForm from './Views/ViewForm';
import ViewTest from './Views/ViewTest';
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ParallaxProvider } from 'react-scroll-parallax';


const Home = () => {

  return (


    <ParallaxProvider>
    <div className="Home">
      <Landing/>
      <View2/>
      <View3/>
      <View4/>
      <View5/>
      <ViewForm/>
      <Footer/> 
      
    </div>
    </ParallaxProvider>
  );
};

export default Home;
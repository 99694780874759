import '../Styles/ViewTest.css'
import { useParallax } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';
// import ondeSeperator from "../Assets/Separator1/ondeSeparator2.png";
import ondeSeperator from "../Assets/Separator1/waveSeparator.png";
// import ondeSeperatorb from "../Assets/Separator1/ondeSeparator2B.png";
import ondeSeperatorb from "../Assets/Separator1/waveSeparatorB.png";
import ugpmRounded from "../Assets/Separator1/rounded_logo.png"

function ViewTest() {



    return(
        
        <div className='circle-containah'>
            <Parallax translateX={['0vw', '100vw']} className='waveSperatorContainer' >
                <img src={ondeSeperator} className='waveSeperator' />
            </Parallax>
            <Parallax translateX={['-100vw', '-200vw']}  className='waveSperatorContainerB' >
                <img src={ondeSeperatorb} className='waveSeperator' />
            </Parallax> 
            <Parallax  translateX={['-100vw', '-10vw']}>
            <img className='circle' src={ugpmRounded} />
            </Parallax>
        </div>
    )


}

export default ViewTest;

import '../Styles/Landing.css';
import fishn from "../Assets/Landing/fishn.mp4";
import fishnT from "../Assets/Landing/fishn-T.mp4";
import fishb from "../Assets/Landing/fishb.mp4";
import fishbT from "../Assets/Landing/fishb-T.mp4";
import fishnk from "../Assets/Landing/fishnk.mp4";
import fishnkT from "../Assets/Landing/fishnk-T.mp4";
import ugpmn from "../Assets/Landing/ugpmn.png";
import ugpmb from "../Assets/Landing/ugpmb.png";
import { useEffect, useState } from 'react';
import { Parallax } from 'react-scroll-parallax';
import waveBottom from "../Assets/view3/wave-bottom.png"

import { gsap } from 'gsap/gsap-core';
import { CSSPlugin } from 'gsap/CSSPlugin'; 


function Landing() {

  const width = window.innerWidth;
  const height = window.innerHeight;
  const newAspectRatio = width / height;
  gsap.registerPlugin(CSSPlugin);

  let firstImg;
  if (newAspectRatio < 1) {
    firstImg = fishnT;
  }
  else{
    firstImg = fishn;
  }
  const [videoSrc, setVideoSrc] = useState(firstImg);

  const [imgSrc, setImgSrc] = useState(ugpmb);
  const [isContentWhite, setContentWhite] = useState(true);
  const [menuOpened, setMenuOpened] = useState(false);




  const handleMenu = (e) => {
    if (!menuOpened){
      gsap.to(`.menu`, { opacity: '1', pointerEvents:'unset', duration: 0.2 });
     
      gsap.to(`.burger-icon span:nth-child(1)`, {
        rotate: '45deg',
        translate: '5px 5px',
        width: '56.5px',
        duration: 0.2,
      });
  
      gsap.to(`.burger-icon span:nth-child(2)`, {
        opacity: 0,
        duration: 0.2,
      });
  
      gsap.to(`.burger-icon span:nth-child(3)`, {
        rotate: '-45deg', 
        translate: '5px -5px',
        width: '56.5px',
        duration: 0.2,
      });


    }
    else{
      gsap.to(`.menu`, { opacity: '0', pointerEvents:'none', duration: 0.2 });
     
       gsap.to(`.burger-icon span:nth-child(1)`, {
        rotate: '0deg',
        translate: '0px',
        width: '40px',
        duration: 0.2,
      });
  
      gsap.to(`.burger-icon span:nth-child(2)`, {
        opacity: 1,
        duration: 0.2,
      });
  
      gsap.to(`.burger-icon span:nth-child(3)`, {
        rotate: '0deg', 
        translate: '0px',
        width: '40px',
        duration: 0.2,
      }); 

    }
    setMenuOpened(!menuOpened);
  }

  const handleVideoEnded = () => {
    if (videoSrc === fishn || videoSrc === fishnT) {
      if (newAspectRatio < 0.6) {
      setVideoSrc(fishbT);
      }
      else{
      setVideoSrc(fishb);
      }
      setImgSrc(ugpmn);
      setContentWhite(false);

    }
    else if (videoSrc === fishb || videoSrc === fishbT) {
      if (newAspectRatio < 0.6) {
        setVideoSrc(fishnkT);
        }
        else{
        setVideoSrc(fishnk);
        }
      setContentWhite(true);
      setImgSrc(ugpmb);
    }

    else if (videoSrc === fishnk || videoSrc === fishnkT) {
      if (newAspectRatio < 0.6) {
        setVideoSrc(fishnT);
      }
        else{
          setVideoSrc(fishn);
        }
    }

  };

  
  
  return (



    <div id="landing-container">



 

      
      <video autoPlay muted id="myVideo" src={videoSrc}  onEnded={handleVideoEnded} className="video-transition">

      </video>


    <div className='ContentLandingText'>

    <img src={imgSrc} id="ugpmImg" />
    <div className="content-Title"  style={{  color: isContentWhite ? 'white' : 'black' }}>
    Faites décoller votre CA 
    <br/>Avec une stratégie de croissance à 360°
    </div>

    <div className="content-Text"  style={{  color: isContentWhite ? 'white' : 'black' }}>
    Sans internet, sans les réseaux sociaux, impossible de prospérer.
    <br />
    Mais ça vous le savez déjà !
    <br />

    <br />
    Recruter ? se former ? c'est  du temps et de l'argent.
    <br />
    Bref on tourne en rond.
    <br />
    <br />
    Il y a pourtant une autre option…
    
    </div>

    </div>


    <a href="#Contact">

    <button className="gradient-button">Contactez un expert</button>
    </a>

    <div className="burger-menu" onClick={(e) => handleMenu(e)}>
  <label for="burger-toggle" className="burger-icon">
    <span></span>
    <span></span>
    <span></span>
  </label>

  <nav className="menu" >
    <div className='backgroundMenu'/>
    <ul>
      <li><a href="#landing-container">Accueil</a></li>
      <li><a href="#View2">Prestations</a></li>
      <li><a href="#View3">Notre méthode</a></li>
      <li><a href="#FAQ" >F.A.Q</a></li>
      <li><a href="#Contact">Nous contacter</a></li>
    </ul>
  </nav>

</div>



<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className='wave' style={{display: 'none'}}>
  <path fill="#f15116" fillOpacity="1" d="M0,256L80,261.3C160,267,320,277,480,282.7C640,288,800,288,960,277.3C1120,267,1280,245,1360,234.7L1440,224L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
</svg>



{/* <Parallax translateX={['0vw', '-100vw']} className='waveBottomLandingContainer'> */}
         <img src={waveBottom} alt='wave' className='waveBottomLandingContainer' />
        {/* </Parallax> */}

    </div>




  );
  
      }

export default Landing;
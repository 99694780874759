import '../../Styles/Question.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { gsap } from 'gsap/gsap-core';
import { CSSPlugin } from 'gsap/CSSPlugin'; 
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

function Question(props) {

    gsap.registerPlugin(CSSPlugin);
    const [displayResponse, setDisplayResponse] = useState(false);

    function expand(){
        if (displayResponse){
            gsap.to(`#questionPlus-${props.index}`, { rotate: '0deg', duration: 1 });
            gsap.to(`#response-${props.index}`, { height: '0', duration: 0.8 });
        }
        else{
            const allQuestions = document.querySelectorAll('.response');
            allQuestions.forEach((question) => {
              const questionIndex = question.getAttribute('data-index');
              if (questionIndex !== props.index) {
                gsap.to(`#response-${questionIndex}`, { height: '0', duration: 0.6 });
                gsap.to(`#response-${questionIndex}`, { margin: '0', duration: 0.6 });
      
            }
            });
            gsap.to(`#questionPlus-${props.index}`, { rotate: '360deg)', duration: 1 });
            gsap.to(`#response-${props.index}`, { height: 'auto', duration: 0.6 });
            gsap.to(`#response-${props.index}`, { marginTop: '1.5svh', duration: 0.6 });
            gsap.to(`#response-${props.index}`, { marginBottom: '1.5svh', duration: 0.6 });

        }
        setDisplayResponse(!displayResponse);
    }

    return(
        <>
        <div className='questionContainer' id={`question-${props.index}`} onClick={expand} style={{cursor: 'pointer'}}>
           {props.question}
            <div className='expand' onClick={expand} id={`questionPlus-${props.index}`} >
                <FontAwesomeIcon icon={faPlusCircle} 
                    style={{color: '#f66128'}}
                 />
            </div>

        </div>
        <div
            className={`response`}
            id={`response-${props.index}`}
            data-index={props.index}
            >

{props.reponse.split(/\r?\n|\r/).map((line, index) => (
          line.trim() === '' ? <> </> : <p>{line} </p>
        ))}

           
        </div>
        </>
    )

}

export default Question;
import '../Styles/Footer.css';

import fish from '../Assets/Footer/fish_Footer.png';
import { HubspotForm } from 'react-hubspot-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import React, { useEffect, useRef, useState } from 'react';
import ugpmn from "../Assets/Footer/ugpmn.png";
import ugpmb from "../Assets/Footer/ugpmb.png";

function  Footer(){

    const openInstagramLink = () => {
        const instagramUrl = 'https://www.instagram.com/ugppme/';
        window.open(instagramUrl, '_blank');
      };
    

    const openTwitterLink = () => {
        const instagramUrl = 'https://www.linkedin.com/company/ugppme/';
        window.open(instagramUrl, '_blank');
      };
    

    return( 
        <div className="FooterContainer">
            <div className='firstDivFooter'>

            <div className='FooterContainerImg'>
           <img className='FooterFish' src={ugpmb} />
            <div className='IconsContainer'>
            <FontAwesomeIcon
      icon={faLinkedin}
      onClick={openTwitterLink}
      style={{ cursor: 'pointer', animation: 'moveElement2 4s infinite' }}
      className='iconSocialFooter'
    />
                <FontAwesomeIcon
      icon={faInstagram}
      onClick={openInstagramLink}
      style={{ cursor: 'pointer', animation: 'moveElement1 4s infinite' }}
      className='iconSocialFooter'

    />
            </div>
            </div>

            <div className='FooterContainerContact'>
            <span style={{color: '#8d3ef4'}} className='Contact'>Contact</span>
            <span>06 22 36 79 21</span>
            <span>contact@ugppme.com</span>
            </div>
                
            </div>
            
        </div>
    )
}

export default Footer;
import axios from "axios";
import { useState, createContext } from "react";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  // Déclaration States d'authentification
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_BACK}/api/getToken`, {
        username: username,
        password: password,
      })
      .then((res) => {
        console.log("authenticated gj");
        localStorage.setItem("token", res.data.token);
        navigate("/dashboard");
      }).catch(err => setError('Authentification echoué!'))
      
      
      
      
      ;
  };

  return (
    <AuthContext.Provider
      value={{
        username,
        setUsername,
        password,
        setPassword,
        handleSubmit,
        error,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
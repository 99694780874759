import React from 'react';
import ugpmn from '../../Assets/Login/ugpmn.png'
import '../../Styles/Admin/Login.css';
import { AuthContext } from '../../Context/authContext';
import { useContext } from 'react';

export default function Login() {

  const { username, setUsername, password, setPassword, handleSubmit, error} =
    useContext(AuthContext)

    return(
      <div className="login-wrapper">
        <div className='login-biggest-wrap'/>
        <div className='login-wrap'>
            <div className='leftLogin'></div>
            <div className='rightLogin'>
            <div className='subrightlogin'>
                
                <div className='LogoContainerLogin'>
                    <img src={ugpmn} />
                    Upggme
                </div>
                <div>Se connecter</div>
                <div className='error'>{error}</div>
                <form onSubmit={(e) => handleSubmit(e)}>
                <input type="text" id="username" 
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Nom d'utilisateur"/>
    
                <input type="password" id="password" 
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Mot de passe"/>
                
                <input type="submit" value="Se connecter"/>
                </form>
                <div className='copyrightLogin'>© 2023 Upggme. Tous droits réservés.</div>
            </div>
            </div>

        </div>

      
      </div>
    )
  }
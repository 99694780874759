import '../Styles/View5.css';
import Card5 from "../SubViews/View5/Card5"
import waveTop from "../Assets/View5/wave-top.png"
import waveBottom from "../Assets/View5/wave-bottom.png"
import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from 'swiper';
import { Autoplay } from 'swiper';
import { Parallax } from 'react-scroll-parallax';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import ugplink from '../Assets/View5/ugpmb.png';
import ugpinsta from '../Assets/View5/rounded_logo.png'
import { useContext } from 'react';
import {BlogSociauxContext} from '../Context/blogsSociaux';

function View5() {
  const [datas, setDatas] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const { blogSociaux} =
    useContext(BlogSociauxContext);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () =>   {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let url_media_count = process.env.REACT_APP_ENDPOINT_MEDIA_COUNT;
        let url = process.env.REACT_APP_ENDPOINT_DATA;
        const media_count_data = await fetch(url_media_count);
        const response = await fetch(url);
        const data = await response.json();
        const media_count = await media_count_data.json();
        setDatas(data.slice(0,media_count));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  
    return (
   <div className='view5BiggestContainer'>

        <Parallax translateX={['0vw', '-100vw']} >
        <img className='waveView5' src={waveTop} />
        </Parallax>


        <Parallax translateX={['0vw', '-100vw']} className='waveView5BotContainer'>
          <img className='waveView5Bot' src={waveBottom} />
        </Parallax>


        <h1 className='waveView5Title'>
        
        Nos actualités
        </h1>
        <div className='view5CardsContainer'>
        <Swiper
  spaceBetween={screenWidth < 600 ? 0 : 10}
  slidesPerView={screenWidth < 600 ? 1 : 4}
  navigation={true}
  pagination={true}
  {...(screenWidth > 600
    ? {}
    : {
        autoplay: {
          delay: 200000,
          disableOnInteraction: true,
        },
      })}
  modules={[Pagination, Navigation, Autoplay]}
>
      <SwiperSlide>
        <Card5
           mainCard='yes'
           network='Instagram'
           urlTo='https://www.instagram.com/ugppme'
           textContent='undefined'
          //  backgroundColor='linear-gradient(to bottom, #405DE6, #5851DB, #833AB4, #C13584, #E1306C, #FD1D1D, #F56040, #FFC045)'
           backgroundColor=''
           clickMeColor='purple'
           icon={ugpinsta}
         />
</SwiperSlide>


{blogSociaux && blogSociaux.map((blogSocial, index) => (

(blogSocial.display_blog === 1 && blogSocial.blog_type=='instagram') ? (
  <SwiperSlide>
<Card5
 textColor='white'
 urlTo={blogSocial.url_blog}
 backgroundColor='white'
 backgroundImage={blogSocial.img_blog}
 textContent={blogSocial.content_blog}
 clickMeColor='linear-gradient(90deg, #405DE6, #5851DB, #833AB4, #C13584, #E1306C, #FD1D1D, #F56040, #FFC045)'
 icon={ugpinsta}
 network="Instagram"
 socialColor='linear-gradient(90deg, #405DE6, #5851DB, #833AB4, #C13584, #E1306C, #FD1D1D, #F56040, #FFC045)'
 />
  </SwiperSlide>          ) : (
    <></>
)

))} 
      </Swiper>
        </div>

        <div className='view5CardsContainer'>
        <Swiper
  spaceBetween={screenWidth < 600 ? 0 : 10}
  slidesPerView={screenWidth < 600 ? 1 : 4}
  navigation={true}
  pagination={true}
  modules={[Pagination, Navigation]}
>
      <SwiperSlide>
        <Card5
           mainCard='yes'
           network='Linkedin'
           urlTo='https://www.linkedin.com/company/ugppme/'
           textContent='undefined'
          //  backgroundColor='linear-gradient(to right, #0077B5, #00A0DC, #0a66c2)'
           backgroundColor=''
           clickMeColor='pink'
           icon={ugplink}
           comment={'À venir bientôt ...'}
         />
         
      </SwiperSlide>
      {blogSociaux && blogSociaux.map((blogSocial, index) => (

          (blogSocial.display_blog === 1 && blogSocial.blog_type=='linkedin') ? (
            <SwiperSlide>
          <Card5
           textColor='white'
           urlTo={blogSocial.url_blog}
           backgroundColor='white'
           backgroundImage={blogSocial.img_blog}
           textContent={blogSocial.content_blog}
           clickMeColor='linear-gradient(to right, #0077B5, #0A66C2)'
           icon={ugpinsta}
           network="Linkedin"
           socialColor='linear-gradient(90deg, #405DE6, #5851DB, #833AB4, #C13584, #E1306C, #FD1D1D, #F56040, #FFC045)'
          />
            </SwiperSlide>          ) : (
              <></>
)

      ))} 

 </Swiper>
 </div>


      </div>


    );
  }
  

export default View5;

import '../Styles/View6.css';
import waveTop from "../Assets/view4/waveTop.png";
import waveBottom from "../Assets/view4/waveBottom.png";
import { Parallax } from 'react-scroll-parallax';

function View6() {

    return(
        <div className='View6Container'>
        <h1>Votre business ne s'arrête pas à l'image.</h1>
        <h2>Avoir un (beau) site web, c'est bien.</h2>
        <h2>Ancrer votre marque dans l'esprit de votre communauté, c'est mieux.</h2>
        <h2>C'est adopter une vision long terme et c'est vital.</h2>
        <h2>Pour passer votre entreprise au niveau supérieur et utiliser tout votre potentiel…</h2>
        <h2>Cliquez sur le bouton juste à droite.</h2>

       
        <a href="#Contact">
<button className="gradient-button rdvButton">Je prends rendez-vous</button>
</a>



        <Parallax translateX={['0vw', '100vw']} className='waveSperatorContainer' >

<img src={waveTop} className='waveTopView4' />
  </Parallax>

        <Parallax translateX={['0vw', '-100vw']} className='waveBottomView4Containerr'>

<img src={waveBottom} className='waveBottomView4'  />
</Parallax>


        </div>
    )
}

export default View6;
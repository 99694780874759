import '../../Styles/StackView3.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

function StackView3(props) {



    return(
    <div className="StackView3Container" style={{ backgroundColor: props.backColor }}>
        <div className='StackView3Header'> {props.title}</div>
        <div className='StackView3SubHeader'> 
        <div className='highleted' style={{textDecorationColor: props.arrowColor}}> {props.highlighted}</div>
        <br/>
        {props.content.split(/\r?\n|\r/).map((line, index) => (
    line.trim() === '' ? <> </> : <>{line} <br/>  </>
  ))}

      {props.subContent &&
      
      <ul className='goodlist'>
        
      {props.subContent.split(/\r?\n|\r/).map((line, index) => (
          line.trim() === '' ? <> </> : <li>{line} </li>
        ))}

      </ul>

      }

      <div>
        {props.addedText}
      </div>

        </div>

        {props.arrowColor !== 'none' && (
        <FontAwesomeIcon className="arrowStack3" icon={faAngleRight} style={{ color: props.arrowColor }} />
      )}


          
    </div>
    )
}
export default StackView3;
import '../../Styles/Card5.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import ugpmn from '../../Assets/View5/rounded_logo.png'


function Card5(props) {

     const MAX_TEXT_LENGTH = 340;

     let truncatedText = props.textContent; 
  
     if (props.textContent.length > MAX_TEXT_LENGTH) {
       truncatedText = props.textContent.substring(0, MAX_TEXT_LENGTH) + '...';
     }

     const openInNewTab = (url) => {
      window.open(url, "_blank", "noreferrer");
    };
    if (props.mainCard){
      return(
        <div className='card5BiggerBiggestContainer mainCard' style={{background: props.backgroundColor, border: 'solid 2px white'}}
        onClick={() => openInNewTab(props.urlTo)}
        >
        <div>Suivez-nous sur <br/>{props.network}</div>
        <img src={props.icon}/>
        <div>{props.comment}</div>
        </div>
      )
    }
    return (
    <div className='card5BiggerBiggestContainer' style={{backgroundColor: props.backgroundColor}}
    onClick={() => openInNewTab(props.urlTo)}
    >
      {/* <div className='iconTitleCOntainer' style={{background: props.socialColor}}>
        <img src={props.icon} />
        <div>Ugppme</div>
      </div> */}

      <img className='hm' src={props.backgroundImage} style={{borderColor: props.borderColor}} />
 
      <div class='cardContent' dangerouslySetInnerHTML={{__html: props.textContent}}>
      </div> 


      <div className='clickMe' style={{ background: props.clickMeColor }}>
        Voir le post sur {props.network}
      </div>

    </div>
        );
};

export default Card5;
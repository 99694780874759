import React, { useState } from 'react';
import '../../Styles/Stack.css';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gsap } from 'gsap/gsap-core';
import { CSSPlugin } from 'gsap/CSSPlugin'; // Import the CSSPlugin


function Stack(props) {

  const [isSecondBoxHovered, setIsSecondBoxHovered] = useState(false);
  const [isPlusVisible, setPlusVisible] = useState(true);
  gsap.registerPlugin(CSSPlugin);


  const toggleIcon = () => {
    if (isPlusVisible){
      gsap.to(`#secondBox-${props.index}`, {
        height: "25svh",
        duration: 0.7
      });
      const element = document.querySelector(`#secondBox-${props.index}`);
      if (element) {
        element.style.padding = "calc(0.4vh + 0.4vw)";
      }
    }
    else{
      gsap.to(`#secondBox-${props.index}`, { height: "0vh", padding: "0px", duration: 0.7 });
    }
    setPlusVisible(!isPlusVisible);
  };
  const handleSecondBoxMouseEnter = () => {
    setIsSecondBoxHovered(true);
  };

  const handleSecondBoxMouseLeave = () => {
    setIsSecondBoxHovered(false);
  };

  return (
    <div className="stack-container">
      <div className={`firstBox`} onClick={toggleIcon}> 
      <img className="imgStack" src={props.imgSrc} alt="" />

        <div className="firstBoxTitle">
          {props.title}
          <div className="subTitle">
          {props.addedText}
        </div>
        </div>


        {isPlusVisible ? (
        <FontAwesomeIcon
          icon={faCirclePlus}
          className='stack2plus'
          style={{ cursor: 'pointer'}}
        />
      ) : (
        <FontAwesomeIcon
          icon={faCircleMinus}
          className='stack2plus'
          style={{ cursor: 'pointer' }}
        />
      )}

    
      </div>


      <div className="secondBox" id={`secondBox-${props.index}`} onMouseEnter={handleSecondBoxMouseEnter} onMouseLeave={handleSecondBoxMouseLeave}
      onClick={toggleIcon}
      >
    {props.text.split(/\r?\n|\r/).map((line, index) => (
      line.trim() === '' ? <></> : <li key={index} className='StackView2Div'>{line}</li>
    ))}




  </div>

    </div>
  );
}

export default Stack;

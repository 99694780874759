import '../Styles/View3.css'
import StackView3 from '../SubViews/View3/StackView3';
import waveBottom from "../Assets/view3/wave-bottom.png"
import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import waveTop from "../Assets/view3/waveTop.png"
import { Parallax } from 'react-scroll-parallax';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


function View3() {
   const [screenWidth, setScreenWidth] = useState(window.innerWidth);

   useEffect(() => {
     const handleResize = () => {
       setScreenWidth(window.innerWidth);
     };
 
     window.addEventListener('resize', handleResize);
 
     return () =>   {
       window.removeEventListener('resize', handleResize);
     };
   }, []);

   if (screenWidth < 600){
      return(
         <div className='View3Container' id="View3">
             <Parallax translateX={['0vw', '-100vw']} className='waveSperatorContainer' >

 <img src={waveTop} className='waveContainerTopView3'/>
        </Parallax>
         <h1 className='HeaderView3'>Et concrètement comment fait-on ?</h1>
         <h2 className='SubHeaverView3'>
         Votre business ne s'arrête pas à l'image ! Avoir un (beau) site web, c'est bien.
        Ancrer votre marque dans l'esprit de votre communauté, c'est mieux.
         Adopter une vision à  long terme, <span>c'est vital.</span>          </h2>

         <div className='View3StackContainer'>
         <Swiper
      spaceBetween={50}
      loop={true}
      slidesPerView={1}
      centeredSlides={true}
      navigation={true}
      pagination={true}
      modules={[Pagination, Navigation]} 
    >
      <SwiperSlide>
      <StackView3
            backColor='#101628'
            arrowColor='#f85301'
            title='DIAGNOSTIC'
            highlighted="On fait le bilan"
            content={`
            Étudions ensemble vos projets et ambitions en 3 phases :
            `}
            subContent={`
            Entretien avec le dirigeant
            Observation en immersion dans l’entreprise
            Synthèse et restitution sur les forces et points d’amélioration de l’entreprise`}
         />
 </SwiperSlide>
 <SwiperSlide>
 <StackView3
            backColor='#fb5402'
            arrowColor='#9340ff'
            title="PLAN D'ACTION"
            highlighted="Passez au niveau supérieur"

            content={`
            En fonction de vos projets, nos consultants vous proposent une stratégie de croissance sur mesure à 360°.
            `}
            subContent={`
            Plan de communication
            Valorisation de produits et services
            Création d'une marque ou d'un site web
            ...
            `}
            addedText={`Rien ne sera laissé au hasard.`}
            />
 </SwiperSlide>
<SwiperSlide>
<StackView3
            backColor='#9340ff'
            arrowColor='#101728'
            title="MISE EN OEUVRE"
            highlighted="Faites décoller votre CA"
            content={`
            Détendez-vous, on s'occupe de tout.
            On met en place votre stratégie :`}
            subContent={`
            Mise en place de votre plan de communication
            Déploiement et gestion de vos réseaux sociaux
            Réorientation de votre positionnement commercial
            `}
            />
</SwiperSlide>
<SwiperSlide>
<StackView3
            backColor='#ff7e46'
            arrowColor='none'
            title="SUIVI"
            highlighted="Devenez inarrêtable"
            content={`
            Tout est organisé, nous faisons vivre votre stratégie dans la durée :`}
            subContent={`
            Nos consultants s'assurent que vos outils fonctionnent durablement
            On fait un bilan régulier des actions et performances
            Et on préconise de nouveaux dispositifs pour allez encore plus loin…
            `}
            />
</SwiperSlide>

    </Swiper>
         </div>


         <Parallax translateX={['0vw', '-100vw']} className='waveBottomView3Container' >
         <img src={waveBottom} alt='wave' className='waveBottomView3' />
        </Parallax>
        <div className='SubHeaverView3'>
        
        Passez votre entreprise au niveau supérieur, cliquez sur le bouton à droite.  
        </div>
        <a href="#Contact">
      <button className="gradient-button rdvButton">Je prends rendez-vous</button>
      </a>
 </div>)

   }

    return(
        <div className='View3Container' id="View3">
            <Parallax translateX={['0vw', '-100vw']} className='waveSperatorContainer' >

         <img src={waveTop} className='waveContainerTopView3'/>
        </Parallax>
              <a href="#Contact">
      <button className="gradient-button rdvButton">Je prends rendez-vous</button>
      </a>


        <h1 className='HeaderView3'>Et concrètement comment fait-on ?</h1>
        <h2 className='SubHeaverView3'>
        Votre business ne s'arrête pas à l'image ! Avoir un (beau) site web, c'est bien.
        Ancrer votre marque dans l'esprit de votre communauté, c'est mieux.
         Adopter une vision à  long terme, <span>c'est vital.</span>
        <br/>
          </h2>
        <div className='View3StackContainer'>
        <StackView3
            backColor='#101628'
            arrowColor='#f85301'
            title='DIAGNOSTIC'
            highlighted="On fait le bilan"
            content={`
            Étudions ensemble vos projets et ambitions en 3 phases :
            `}
            subContent={`
            Entretien avec le dirigeant
            Observation en immersion dans l’entreprise
            Synthèse et restitution sur les forces et points d’amélioration de l’entreprise`}
         />
<StackView3
            backColor='#fb5402'
            arrowColor='#9340ff'
            title="PLAN D'ACTION"
            highlighted="Passez au niveau supérieur"

            content={`
            En fonction de vos projets, nos consultants vous proposent une stratégie de croissance sur mesure à 360°.
            `}
            subContent={`
            Plan de communication
            Valorisation de produits et services
            Création d'une marque ou d'un site web
            ...
            `}
            addedText={`Rien ne sera laissé au hasard.`}
            />

<StackView3
            backColor='#9340ff'
            arrowColor='#101728'
            title="MISE EN OEUVRE"
            highlighted="Faites décoller votre CA"
            content={`
            Détendez-vous, on s'occupe de tout.
            On met en place votre stratégie :`}
            subContent={`
            Mise en place de votre plan de communication
            Déploiement et gestion de vos réseaux sociaux
            Réorientation de votre positionnement commercial
            `}
            />

<StackView3
            backColor='#d24c63'
            arrowColor='none'
            title="SUIVI"
            highlighted="Devenez inarrêtable"
            content={`
            Tout est organisé, nous faisons vivre votre stratégie dans la durée :`}
            subContent={`
            Nos consultants s'assurent que vos outils fonctionnent durablement
            On fait un bilan régulier des actions et performances
            Et on préconise de nouveaux dispositifs pour allez encore plus loin…
            `}
            />

        </div>
        <Parallax translateX={['0vw', '-100vw']} className='waveBottomView3Container' >

      <img src={waveBottom} alt='wave' className='waveBottomView3' />


      </Parallax>
      <div className='SubHeaverView3'>
        
      Passez votre entreprise au niveau supérieur, cliquez sur le bouton à droite.  
      </div>

</div>)
}

export default View3;
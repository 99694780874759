import '../Styles/ViewForm.css';
import HubspotForm from 'react-hubspot-form';
import waveBottom from "../Assets/viewForm/waveBottom.png"
import waveTop from "../Assets/viewForm/waveTop.png"
import { Parallax } from 'react-scroll-parallax';
import { useParallax } from 'react-scroll-parallax';
import JotformEmbed from 'react-jotform-embed';


function ViewForm() {
    return(
        
        <div className='ViewFormContainer' id="Contact"> 
        <h2 className='formulairePhrase'>Restons en contact !</h2>
        
       

           <Parallax translateX={['0vw', '-100vw']} className='waveContainerTopView3' >
        <img src={waveTop} className='waveContainerTopView3'/>
        </Parallax>
        
       
        <div className='formContainer'>

    {/* <HubspotForm
     portalId="26662062"
     formId="3ed4d247-a285-42d3-84a3-c83947ce2abc"
     />   */}
    {/* <JotformEmbed src="https://form.jotformeu.com/230722763640352" /> */}
     <JotformEmbed src="https://form.jotformeu.com/232635353811352" /> 
</div>
     
<Parallax translateX={['0vw', '-100vw']} className='waveContainerDownViewForm' >

<div className='waveContainerDownViewForm'> 
      <img src={waveBottom} alt='wave' />
</div>

</Parallax>


     </div>
     
     )

}

export default ViewForm;